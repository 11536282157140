import React from 'react'

const Post2= () => {
  return (
    <div className="blogpost-section section " id="blogpost">
        <div className="title-wrraper center">
                    <h2> What is headless e-commerce and why you need to use it?
</h2>   
                </div> 

                <div className='post-container'>
                <p>I like to share my knowledge with others. Take a look at some of  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium. technical articles and behind the scene of my side project

</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>

<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>

<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>

<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non, et accusamus, animi dignissimos qui labore, maiores nostrum ducimus esse asperiores sit ullam vel amet hic assumenda porro numquam. Quod, praesentium.
  
</p>
                </div>
    </div>
  )
}

export default Post2